import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Drawer, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowB2BAddAgentModal } from '@/Store/B2B/b2bAgentSlice'
import { useForm } from '@inertiajs/react'
import XTextInput from '@/Mantine/XTextInput'
import XSelect from '@/Mantine/XSelect'
import useMeta from '@/Hooks/useMeta'
import useLocation from '@/Hooks/useLocation'
import XPhoneInput from '@/Mantine/XPhoneInput'
import useAuth from '@/Hooks/useAuth'
import INDIAN_STATES from '@/Constants/IndianStates'

const AddB2BAgentModal = () => {
  const auth = useAuth()
  const meta = useMeta()
  const dispatch = useDispatch()
  const location = useLocation()

  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)

  const [loading, setLoading] = useState(false)

  const branchId =
    meta.branches.find((branch) => {
      return branch.is_default
    })?.id ?? null

  const { data, setData, post, errors, reset, clearErrors, recentlySuccessful } = useForm<{
    agency_name: string
    first_name: string
    last_name: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    phone: string
    state: string
    city: string
    relationship_manager_id: string
    relationship_manager_id_one: string
    relationship_manager_id_two: string
    relationship_manager_id_three: string
    relationship_manager_id_four: string
    relationship_manager_id_five: string
    relationship_manager_id_six: string
    relationship_manager_id_seven: string
  }>({
    agency_name: '',
    first_name: '',
    last_name: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    phone: '',
    state: '',
    city: '',
    relationship_manager_id: '',
    relationship_manager_id_one: '',
    relationship_manager_id_two: '',
    relationship_manager_id_three: '',
    relationship_manager_id_four: '',
    relationship_manager_id_five: '',
    relationship_manager_id_six: '',
    relationship_manager_id_seven: '',
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateShowB2BAddAgentModal(false))
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={700}
      opened={b2bAgentStore.show_b2b_add_agent_modal}
      onClose={() => {
        dispatch(updateShowB2BAddAgentModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack spacing="12px">
          <Typography variant="subheadline">Invite Agent</Typography>
        </HStack>
        <HStack spacing="12px">
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack spacing="12px" style={{ position: 'relative' }} height="100%">
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <HStack>
              <Typography variant="body-small-bold" color={XelaColor.Gray4}>
                Add Details
              </Typography>
            </HStack>
            <VStack spacing="12px">
              <HStack spacing="12px">
                <XTextInput
                  data-autofocus
                  autoFocus
                  required
                  label={'Agency Name'}
                  error={errors.agency_name}
                  value={data.agency_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      agency_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                <XTextInput
                  required
                  label={'First Name'}
                  error={errors.first_name}
                  value={data.first_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      first_name: event.target.value,
                    }))
                  }}
                />
                <XTextInput
                  required
                  label={'Last Name'}
                  error={errors.last_name}
                  value={data.last_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      last_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                <XTextInput
                  required
                  label={'Email'}
                  error={errors.email}
                  value={data.email}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      email: event.target.value,
                    }))
                  }}
                />
                <XPhoneInput
                  error={errors.phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                ></XPhoneInput>
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                {data.country_code === 'IN' && (
                  <XSelect
                    searchable
                    label={'State'}
                    error={errors.state}
                    value={data.state}
                    data={INDIAN_STATES}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          state: value,
                        }))
                      }
                    }}
                  />
                )}
                {data.country_code !== 'IN' && (
                  <XTextInput
                    label={'State'}
                    error={errors.state}
                    value={data.state}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        state: event.target.value,
                      }))
                    }}
                  />
                )}
                <XTextInput
                  label={'City'}
                  error={errors.city}
                  value={data.city}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      city: event.target.value,
                    }))
                  }}
                />
              </HStack>
              <HStack>
                <Typography variant="body-small-bold" color={XelaColor.Gray4}>
                  Assign Relationship Manager
                </Typography>
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                <XSelect
                  required
                  searchable
                  value={data.relationship_manager_id}
                  data={
                    branchId
                      ? meta.branchesUsers[branchId].users.map((counsellor) => {
                          let isCounsellorEnabled = false

                          if (counsellor.role.permissions.includes('b2b_module_access')) {
                            isCounsellorEnabled = true
                          }

                          return {
                            value: counsellor.id.toString(),
                            label: counsellor.name,
                            group: counsellor.branch.branch_name,
                            disabled: !isCounsellorEnabled,
                          }
                        })
                      : []
                  }
                  label="Select Main Relationship Manager"
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        relationship_manager_id: value,
                      }))
                    }
                  }}
                  error={errors.relationship_manager_id}
                />
                <XSelect
                  searchable
                  value={data.relationship_manager_id_one}
                  data={
                    branchId
                      ? meta.branchesUsers[branchId].users.map((counsellor) => {
                          let isCounsellorEnabled = false

                          if (counsellor.role.permissions.includes('b2b_module_access')) {
                            isCounsellorEnabled = true
                          }

                          return {
                            value: counsellor.id.toString(),
                            label: counsellor.name,
                            group: counsellor.branch.branch_name,
                            disabled: !isCounsellorEnabled,
                          }
                        })
                      : []
                  }
                  label="Select Relationship Manager One (Op)"
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        relationship_manager_id_one: value,
                      }))
                    }
                  }}
                  error={errors.relationship_manager_id_one}
                />
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                <XSelect
                  searchable
                  value={data.relationship_manager_id_two}
                  data={
                    branchId
                      ? meta.branchesUsers[branchId].users.map((counsellor) => {
                          let isCounsellorEnabled = false

                          if (counsellor.role.permissions.includes('b2b_module_access')) {
                            isCounsellorEnabled = true
                          }

                          return {
                            value: counsellor.id.toString(),
                            label: counsellor.name,
                            group: counsellor.branch.branch_name,
                            disabled: !isCounsellorEnabled,
                          }
                        })
                      : []
                  }
                  label="Select Relationship Manager Two (Op)"
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        relationship_manager_id_two: value,
                      }))
                    }
                  }}
                  error={errors.relationship_manager_id_two}
                />
                <XSelect
                  searchable
                  value={data.relationship_manager_id_three}
                  data={
                    branchId
                      ? meta.branchesUsers[branchId].users.map((counsellor) => {
                          let isCounsellorEnabled = false

                          if (counsellor.role.permissions.includes('b2b_module_access')) {
                            isCounsellorEnabled = true
                          }

                          return {
                            value: counsellor.id.toString(),
                            label: counsellor.name,
                            group: counsellor.branch.branch_name,
                            disabled: !isCounsellorEnabled,
                          }
                        })
                      : []
                  }
                  label="Select Relationship Manager Three (Op)"
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        relationship_manager_id_three: value,
                      }))
                    }
                  }}
                  error={errors.relationship_manager_id_three}
                />
              </HStack>
              {auth.agency.id === 1111178 && (
                <>
                  <HStack spacing="12px" alignItems="flex-start">
                    <XSelect
                      searchable
                      value={data.relationship_manager_id_four}
                      data={
                        branchId
                          ? meta.branchesUsers[branchId].users.map((counsellor) => {
                              let isCounsellorEnabled = false

                              if (counsellor.role.permissions.includes('b2b_module_access')) {
                                isCounsellorEnabled = true
                              }

                              return {
                                value: counsellor.id.toString(),
                                label: counsellor.name,
                                group: counsellor.branch.branch_name,
                                disabled: !isCounsellorEnabled,
                              }
                            })
                          : []
                      }
                      label="Select Relationship Manager Four (Op)"
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            relationship_manager_id_four: value,
                          }))
                        }
                      }}
                      error={errors.relationship_manager_id_four}
                    />
                    <XSelect
                      searchable
                      value={data.relationship_manager_id_five}
                      data={
                        branchId
                          ? meta.branchesUsers[branchId].users.map((counsellor) => {
                              let isCounsellorEnabled = false

                              if (counsellor.role.permissions.includes('b2b_module_access')) {
                                isCounsellorEnabled = true
                              }

                              return {
                                value: counsellor.id.toString(),
                                label: counsellor.name,
                                group: counsellor.branch.branch_name,
                                disabled: !isCounsellorEnabled,
                              }
                            })
                          : []
                      }
                      label="Select Relationship Manager Five (Op)"
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            relationship_manager_id_five: value,
                          }))
                        }
                      }}
                      error={errors.relationship_manager_id_five}
                    />
                  </HStack>
                  <HStack spacing="12px" alignItems="flex-start">
                    <XSelect
                      searchable
                      value={data.relationship_manager_id_six}
                      data={
                        branchId
                          ? meta.branchesUsers[branchId].users.map((counsellor) => {
                              let isCounsellorEnabled = false

                              if (counsellor.role.permissions.includes('b2b_module_access')) {
                                isCounsellorEnabled = true
                              }

                              return {
                                value: counsellor.id.toString(),
                                label: counsellor.name,
                                group: counsellor.branch.branch_name,
                                disabled: !isCounsellorEnabled,
                              }
                            })
                          : []
                      }
                      label="Select Relationship Manager Six (Op)"
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            relationship_manager_id_six: value,
                          }))
                        }
                      }}
                      error={errors.relationship_manager_id_six}
                    />
                    <XSelect
                      searchable
                      value={data.relationship_manager_id_seven}
                      data={
                        branchId
                          ? meta.branchesUsers[branchId].users.map((counsellor) => {
                              let isCounsellorEnabled = false

                              if (counsellor.role.permissions.includes('b2b_module_access')) {
                                isCounsellorEnabled = true
                              }

                              return {
                                value: counsellor.id.toString(),
                                label: counsellor.name,
                                group: counsellor.branch.branch_name,
                                disabled: !isCounsellorEnabled,
                              }
                            })
                          : []
                      }
                      label="Select Relationship Manager Seven (Op)"
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            relationship_manager_id_seven: value,
                          }))
                        }
                      }}
                      error={errors.relationship_manager_id_seven}
                    />
                  </HStack>
                </>
              )}
            </VStack>
          </VStack>
          <HStack justifyContent="flex-start" spacing="12px">
            <HStack spacing="12px">
              <Button
                label="Invite"
                variant="primary"
                onClick={() => {
                  setLoading(true)
                  post(route('b2b.invites', { _query: { invite_type: 'Invite' } }), {
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }}
              />
            </HStack>
            <HStack spacing="12px" justifyContent="flex-end">
              <Button
                label="Cancel"
                variant="secondary"
                onClick={() => {
                  dispatch(updateShowB2BAddAgentModal(false))
                }}
              />
              <Button
                label="Activate"
                variant="primary"
                onClick={() => {
                  setLoading(true)
                  post(route('b2b.invite-and-activate-agent', { _query: { invite_type: 'Activate' } }), {
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }}
              />
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default AddB2BAgentModal
