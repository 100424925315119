import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { LoadingOverlay, Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateB2BSelectedAgent, updateShowB2BAgentModal } from '@/Store/B2B/b2bAgentSlice'
import { useForm } from '@inertiajs/react'
import XTextInput from '@/Mantine/XTextInput'
import XSelect from '@/Mantine/XSelect'
import INDIAN_STATES from '@/Constants/IndianStates'

const EditB2BAgentModal = () => {
  const dispatch = useDispatch()

  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)

  const [loading, setLoading] = useState(false)

  const { data, setData, post, errors, reset, clearErrors, recentlySuccessful } = useForm<{
    agency_name: string
    first_name: string
    last_name: string
    country_code: string
    state: string
    city: string
  }>({
    agency_name: '',
    first_name: '',
    last_name: '',
    country_code: '',
    state: '',
    city: '',
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateShowB2BAgentModal(false))
      dispatch(dispatch(updateB2BSelectedAgent(null)))
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (b2bAgentStore.b2b_selected_agent !== null) {
      setData((prevData) => ({
        ...prevData,
        agency_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.agency_name : '',
        first_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.first_name : '',
        last_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.last_name : '',
        country_code: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.country_code : '',
        state: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.state : '',
        city: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.city : '',
      }))
    }
  }, [b2bAgentStore.b2b_selected_agent])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      radius={16}
      size={600}
      opened={b2bAgentStore.show_b2b_agent_modal}
      onClose={() => {
        dispatch(updateShowB2BAgentModal(false))
        dispatch(dispatch(updateB2BSelectedAgent(null)))
        clearErrors()
        reset()
      }}
    >
      <VStack spacing="12px">
        <HStack spacing="12px">
          <Typography variant="subheadline">Edit Agent</Typography>
        </HStack>
        <HStack spacing="12px">
          <Divider></Divider>
        </HStack>
        <div
          style={{
            padding: '10px',
            position: 'relative',
            width: '100%',
          }}
        >
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px">
            <HStack>
              <Typography variant="body-small-bold" color={XelaColor.Gray4}>
                Agent Details
              </Typography>
            </HStack>
            <VStack spacing="12px" style={{ height: '350px' }}>
              <HStack spacing="12px" alignItems="flex-start">
                <XTextInput
                  data-autofocus
                  required
                  label={'First Name'}
                  error={errors.first_name}
                  value={data.first_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      first_name: event.target.value,
                    }))
                  }}
                />
                <XTextInput
                  required
                  label={'Last Name'}
                  error={errors.last_name}
                  value={data.last_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      last_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
              <HStack>
                <Divider variant="dotted"></Divider>
              </HStack>
              <HStack>
                <Typography variant="body-small-bold" color={XelaColor.Gray4}>
                  Agency Details
                </Typography>
              </HStack>
              <HStack>
                <XTextInput
                  required
                  label={'Agency Name'}
                  error={errors.agency_name}
                  value={data.agency_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      agency_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
              <HStack spacing="12px" alignItems="flex-start">
                {data.country_code === 'IN' && (
                  <XSelect
                    searchable
                    label={'State'}
                    error={errors.state}
                    value={data.state}
                    data={INDIAN_STATES}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          state: value,
                        }))
                      }
                    }}
                  />
                )}
                {data.country_code !== 'IN' && (
                  <XTextInput
                    label={'State'}
                    error={errors.state}
                    value={data.state}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        state: event.target.value,
                      }))
                    }}
                  />
                )}
                <XTextInput
                  label={'City'}
                  error={errors.city}
                  value={data.city}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      city: event.target.value,
                    }))
                  }}
                />
              </HStack>
            </VStack>
            <HStack justifyContent="flex-end" spacing="12px">
              <Button
                label="Cancel"
                variant="secondary"
                onClick={() => {
                  dispatch(updateShowB2BAgentModal(false))
                  dispatch(dispatch(updateB2BSelectedAgent(null)))
                  clearErrors()
                  reset()
                }}
              />
              <Button
                label="Save"
                variant="primary"
                onClick={() => {
                  if (b2bAgentStore.b2b_selected_agent !== null) {
                    setLoading(true)
                    post(
                      route('b2b.agents.edit', {
                        id: b2bAgentStore.b2b_selected_agent.id,
                      }),
                      {
                        onFinish: () => {
                          setLoading(false)
                        },
                      }
                    )
                  }
                }}
              />
            </HStack>
          </VStack>
        </div>
      </VStack>
    </Modal>
  )
}

export default EditB2BAgentModal
