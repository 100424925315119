import React, { useEffect, useState } from 'react'
import { Divider, HStack, Step, Steps, VStack, XelaColor } from '@/XelaReact'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowHubAddLeadModal } from '@/Store/Hub/hubLeadSlice'
import UploadBulkDocumentsHubForm from '@/Layouts/Hub/Forms/UploadBulkDocumentsHubForm'
import AddNewLeadFromHubForm from '@/Layouts/Hub/Forms/AddNewLeadFromHubForm'

const AddQuickHubLeadModal = () => {
  const dispatch = useDispatch()
  const hubLeadStore = useSelector((state: RootState) => state.hubLead)

  const [firstStepStatus, setFirstStepStatus] = useState<'current' | 'success'>('current')
  const [secondStepStatus, setSecondStepStatus] = useState<'default' | 'current' | 'success'>('default')

  useEffect(() => {
    if (hubLeadStore.lead_form_submitted) {
      setFirstStepStatus('success')
      setSecondStepStatus('current')
    }
  }, [hubLeadStore.lead_form_submitted])

  useEffect(() => {
    if (hubLeadStore.lead_documents_uploaded) {
      setSecondStepStatus('success')
    }
  }, [hubLeadStore.lead_documents_uploaded])

  useEffect(() => {
    if (hubLeadStore.show_hub_add_lead_modal) {
      setFirstStepStatus('current')
      setSecondStepStatus('default')
    }
  }, [hubLeadStore.show_hub_add_lead_modal])

  return (
    <Drawer
      position="left"
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={800}
      opened={hubLeadStore.show_hub_add_lead_modal}
      onClose={() => {
        dispatch(updateShowHubAddLeadModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack justifyContent="center">
          <Steps
            size={'medium'}
            titleCurrentColor={XelaColor.Gray3}
            captionCurrentColor={XelaColor.Gray7}
            indicatorBgSuccessColor={XelaColor.Green1}
            indicatorBgDefaultColor={XelaColor.Gray10}
            steps={[
              new Step(1, '', 'Add Lead Details', firstStepStatus),
              new Step(2, '', 'Upload Documents', secondStepStatus),
            ]}
            variant="horizontal"
          />
        </HStack>
        <HStack>
          <Divider />
        </HStack>
        {firstStepStatus === 'current' && <AddNewLeadFromHubForm />}
        {secondStepStatus === 'current' && <UploadBulkDocumentsHubForm />}
      </VStack>
    </Drawer>
  )
}

export default AddQuickHubLeadModal
