import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Document = App.Models.Document
import IeltsLead = App.Models.IeltsLead

export interface IeltsLeadState {
  ielts_lead_id: string | null
  ielts_lead: IeltsLead | null
  show_edit_personal_detail_modal: boolean
  show_edit_visa_passport_detail_modal: boolean
  show_edit_application_detail_modal: boolean
  show_edit_language_detail_modal: boolean
  show_edit_work_experience_detail_modal: boolean
  show_ielts_lead_exam_date_transactor_modal: boolean
  show_result_language_detail_modal: boolean
  branch_id: string | null
  portal: string
  show_upload_document_modal: boolean
  document_belongs_to: string
  document_entity: string
  document_entity_id: string | null
  download_documents: Document[] | null
  show_document_download_modal: boolean
  lead_manager_id: string | null
  is_lead_manager_changed: boolean
  show_lead_convert_modal: boolean
  show_coaching_lead_import_modal: boolean
  lead_type: string | null
  is_cold: boolean
  cold_reason: string
  cold_status: string
  reload_all_leads: boolean
  current_leads_filters: {
    [key: string]: string
  }
  previous_leads_filters: {
    [key: string]: string
  }
  show_all_leads_filter_drawer: boolean
  selected_lead_ids: string[]
  selected_leads: IeltsLead[]
  quick_action_show_lead_manager_modal: boolean
  show_branch_change_modal: boolean
  task_counsellor_id: string | null
  follow_up_counsellor_id: string | null
  document: Document | null
  show_edit_upload_document_modal: boolean
  selected_ielts_lead_id: number | null
  is_leads_notes_opened: boolean
}

const initialState: IeltsLeadState = {
  ielts_lead_id: null,
  ielts_lead: null,
  show_edit_personal_detail_modal: false,
  show_edit_visa_passport_detail_modal: false,
  show_edit_application_detail_modal: false,
  show_edit_language_detail_modal: false,
  show_edit_work_experience_detail_modal: false,
  show_ielts_lead_exam_date_transactor_modal: false,
  show_result_language_detail_modal: false,
  branch_id: null,
  portal: 'Agency',
  show_upload_document_modal: false,
  document_belongs_to: 'Applicant',
  document_entity: 'IELTS Lead',
  document_entity_id: null,
  download_documents: null,
  show_document_download_modal: false,
  lead_manager_id: null,
  is_lead_manager_changed: false,
  show_lead_convert_modal: false,
  show_coaching_lead_import_modal: false,
  lead_type: null,
  is_cold: false,
  cold_reason: '',
  cold_status: '',
  current_leads_filters: {
    branch_id: '',
    lead_manager_id: '',
    lead_source: '',
    sub_lead_source: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  previous_leads_filters: {
    branch_id: '',
    lead_manager_id: '',
    lead_source: '',
    sub_lead_source: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  reload_all_leads: false,
  show_all_leads_filter_drawer: false,
  selected_lead_ids: [],
  selected_leads: [],
  quick_action_show_lead_manager_modal: false,
  show_branch_change_modal: false,
  task_counsellor_id: null,
  follow_up_counsellor_id: null,
  document: null,
  show_edit_upload_document_modal: false,
  selected_ielts_lead_id: null,
  is_leads_notes_opened: false,
}

export const ieltsLeadSlice = createSlice({
  name: 'ieltsLeadSlice',
  initialState,
  reducers: {
    updateIeltsLeadId(state, action: PayloadAction<string | null>) {
      state.ielts_lead_id = action.payload
    },
    updateIeltsLead(state, action: PayloadAction<IeltsLead | null>) {
      state.ielts_lead = action.payload
    },
    updateShowEditPersonalDetailModal(state, action: PayloadAction<{ show: boolean; ieltsLead: IeltsLead | null }>) {
      state.show_edit_personal_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowEditVisaPassportDetailModal(
      state,
      action: PayloadAction<{ show: boolean; ieltsLead: IeltsLead | null }>
    ) {
      state.show_edit_visa_passport_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowEditLanguageDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        ieltsLead: IeltsLead | null
      }>
    ) {
      state.show_edit_language_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowEditApplicationDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        ieltsLead: IeltsLead | null
      }>
    ) {
      state.show_edit_application_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowEditWorkExperienceDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        ieltsLead: IeltsLead | null
      }>
    ) {
      state.show_edit_work_experience_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowIeltsLeadExamDateTransactorModal(
      state,
      action: PayloadAction<{
        show: boolean
        ieltsLead: IeltsLead | null
      }>
    ) {
      state.show_ielts_lead_exam_date_transactor_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateShowResultLanguageDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        ieltsLead: IeltsLead | null
      }>
    ) {
      state.show_result_language_detail_modal = action.payload.show
      state.ielts_lead = action.payload.ieltsLead
    },
    updateIeltsLeadBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateDocumentBelongsTo(state, action: PayloadAction<string>) {
      state.document_belongs_to = action.payload
    },
    updateShowUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        ielts_lead_id: string | null
        belongs_to: string
      }>
    ) {
      state.show_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.document_belongs_to = action.payload.belongs_to
    },
    updateDownloadDocumentsModal(
      state,
      action: PayloadAction<{
        show: boolean
        ielts_lead_id: string | null
        documents: Document[] | null
      }>
    ) {
      state.show_document_download_modal = action.payload.show
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.download_documents = action.payload.documents
    },
    updateIsLeadManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        ielts_lead_id: string | null
        lead_manager_id: string | null
        branch_id: string | null
      }>
    ) {
      state.is_lead_manager_changed = action.payload.show
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.lead_manager_id = action.payload.lead_manager_id
      state.branch_id = action.payload.branch_id
    },
    updateShowLeadConvertModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        ielts_lead_id: string | null
        lead_type: string | null
      }>
    ) {
      state.show_lead_convert_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.lead_type = action.payload.lead_type
    },
    updateShowCoachingLeadImportModal(state, action: PayloadAction<boolean>) {
      state.show_coaching_lead_import_modal = action.payload
    },
    updateIsCold(state, action: PayloadAction<boolean>) {
      state.is_cold = action.payload
    },
    updateColdLeadStatus(state, action: PayloadAction<string>) {
      state.cold_status = action.payload
    },
    updateColdLeadReason(state, action: PayloadAction<string>) {
      state.cold_reason = action.payload
    },
    showAllLeadFilterDrawer(state) {
      state.show_all_leads_filter_drawer = true
    },
    hideAllLeadFilterDrawer(state) {
      state.show_all_leads_filter_drawer = false
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
    updateCurrentLeadFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.current_leads_filters = action.payload
    },
    updatePreviousLeadFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.previous_leads_filters = action.payload
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeads(state, action: PayloadAction<IeltsLead[]>) {
      state.selected_leads = action.payload
    },
    updateQuickActionShowLeadManagerModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
      }>
    ) {
      state.quick_action_show_lead_manager_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateIeltsLeadShowBranchChangeModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        ielts_lead_id: string | null
        lead_manager_id: string | null
        task_counsellor_id: string | null
        follow_up_counsellor_id: string | null
      }>
    ) {
      state.show_branch_change_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.lead_manager_id = action.payload.lead_manager_id
      state.task_counsellor_id = action.payload.task_counsellor_id
      state.follow_up_counsellor_id = action.payload.follow_up_counsellor_id
    },
    updateShowEditUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        ielts_lead_id: string | null
        belongs_to: string
        document: Document | null
      }>
    ) {
      state.show_edit_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.ielts_lead_id = action.payload.ielts_lead_id
      state.document_belongs_to = action.payload.belongs_to
      state.document = action.payload.document
    },
    updateIsIeltsLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_ielts_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_ielts_lead_id = action.payload.selected_ielts_lead_id
      state.is_leads_notes_opened = action.payload.is_leads_notes_opened
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIeltsLeadId,
  updateIeltsLead,
  updateShowEditPersonalDetailModal,
  updateShowEditVisaPassportDetailModal,
  updateShowEditLanguageDetailModal,
  updateShowEditApplicationDetailModal,
  updateShowEditWorkExperienceDetailModal,
  updateIeltsLeadBranchId,
  updateShowUploadDocumentModal,
  updateDocumentBelongsTo,
  updateDownloadDocumentsModal,
  updateIsLeadManagerChanged,
  updateShowLeadConvertModal,
  updateShowIeltsLeadExamDateTransactorModal,
  updateShowResultLanguageDetailModal,
  updateShowCoachingLeadImportModal,
  updateIsCold,
  updateColdLeadReason,
  updateColdLeadStatus,
  showAllLeadFilterDrawer,
  hideAllLeadFilterDrawer,
  reloadAllLeads,
  updateCurrentLeadFilters,
  updatePreviousLeadFilters,
  updateSelectedLeadIds,
  updateSelectedLeads,
  updateQuickActionShowLeadManagerModal,
  updateIeltsLeadShowBranchChangeModal,
  updateShowEditUploadDocumentModal,
  updateIsIeltsLeadsNotesOpened,
} = ieltsLeadSlice.actions

export default ieltsLeadSlice.reducer
