import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Button, Center, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router, usePage } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconOff from '@/Icons/IconOff'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { IconSearch, IconSpeakerphone } from '@tabler/icons-react'
import { toggleHubCommissionSubSidebar, toggleSidebar, updateShowSearch } from '@/Store/globalSlice'
import useMeta from '@/Hooks/useMeta'
import IconHome from '@/Icons/IconHome'
import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import IconPlus from '@/Icons/IconPlus'
import { updateShowHubAddLeadModal } from '@/Store/Hub/hubLeadSlice'
import useWidthResize from '@/Hooks/useWidthResize'
import IconSettings from '@/Icons/IconSettings'
import IconBox from '@/Icons/IconBox'
import IconDocuments from '@/Icons/IconDocuments'
import { useClickOutside } from '@mantine/hooks'
import HubSubSidebar from '@/Components/Hub/HubSubSidebar'
import IconDrawerClose from '@/Icons/IconDrawerClose'
import IconDrawerOpen from '@/Icons/IconDrawerOpen'

export const SIDEBAR_ITEMS = [
  {
    type: 'item',
    label: 'Home',
    route: '/dashboard',
    icon: <IconHome width="18" height="18" viewBox="0 0 16 16" />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Course Wiz',
    route: '/courses',
    icon: <IconBox width="18" height="17" viewBox="0 0 16 17"></IconBox>,
    roles: ['*'],
  },
  {
    type: 'divider',
  },
  {
    type: 'item',
    label: 'Leads',
    route: '/leads',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Announcements',
    route: '/announcements',
    icon: <IconSpeakerphone color={XelaColor.Blue3} stroke={1.2} size={22} fill={XelaColor.Blue12}></IconSpeakerphone>,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Commissions',
    route: '/hub/commissions',
    icon: <IconDocuments height="18" width="18" viewBox="0 0 16 16"></IconDocuments>,
    roles: ['*'],
  },
  {
    type: 'grow',
  },
  {
    type: 'item',
    label: 'Settings',
    route: '/personal-details',
    icon: <IconSettings height="18" width="18"></IconSettings>,
    roles: ['*'],
  },
]

const HubSidebar: FC = () => {
  const dimension = useWidthResize()
  const dispatch = useDispatch()

  const { url } = usePage()
  const agent = useAuth().user
  const agentRole = useAuth().role
  const agency = useAuth().agency

  const meta = useMeta()
  const globalStore = useSelector((state: RootState) => state.global)

  const [isOpen, setOpen] = useState(true)
  const [showElement, setShowElement] = useState(true)
  const outsideRef = useClickOutside(() => {
    dispatch(toggleHubCommissionSubSidebar(false))
  })
  // Check width and perform desired action
  useEffect(() => {
    if (dimension < 1200) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [dimension])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowElement(true)
      }, 150)
    } else {
      setShowElement(false)
    }
  }, [isOpen])

  useEffect(() => {
    dispatch(toggleSidebar(isOpen))
  }, [isOpen])

  const avatar = useSelector((state: RootState) => state.user.avatar)

  return (
    <div
      ref={outsideRef}
      className="sidebar"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderLeft: `5px solid ${XelaColor.Pink3}`,
        gap: '12px',
        height: '100vh',
        width: isOpen ? '260px' : '100px',
        backgroundColor: XelaColor.White,
        overflowX: !isOpen ? 'visible' : 'hidden',
        overflowY: 'auto',
      }}
    >
      <HStack justifyContent={isOpen ? 'space-between' : 'flex-end'}>
        <VStack spacing="4px">
          <HStack spacing={isOpen ? '20px' : '0px'} justifyContent="center">
            {showElement && (
              <VStack>
                <Typography variant={'sub-caption'} color={XelaColor.Gray3} style={{ fontSize: '10px' }}>
                  Agency
                </Typography>
                <Typography
                  variant={'subheadline'}
                  color={XelaColor.Blue3}
                  style={{
                    width: '150px',
                    wordBreak: 'break-all',
                    maxHeight: '90px',
                    overflowY: 'hidden',
                  }}
                >
                  {agency.agency_name}
                </Typography>
              </VStack>
            )}
            <Center
              className="sidebar-icon-btn"
              onClick={() => {
                setOpen(!isOpen)
              }}
              width="36px"
              height="36px"
            >
              {isOpen ? (
                <IconDrawerClose width="15" height="16" viewBox="0 0 15 16" />
              ) : (
                <IconDrawerOpen width="15" height="16" viewBox="0 0 15 16" />
              )}
            </Center>
          </HStack>
        </VStack>
      </HStack>
      <Divider variant="dotted" />
      <Center className="sidebar-icon-btn">
        <HStack spacing="8px">
          {!isOpen && (
            <Button
              style={{ padding: '16px' }}
              leftIcon={<IconPlus width={17} height={17} viewBox="0 0 15 15"></IconPlus>}
              variant="secondary"
              onClick={() => {
                dispatch(updateShowHubAddLeadModal(true))
              }}
            ></Button>
          )}
          {isOpen && (
            <Button
              style={{ padding: '12px' }}
              leftIcon={<IconSearch></IconSearch>}
              variant="secondary"
              onClick={() => {
                dispatch(updateShowSearch(true))
              }}
            ></Button>
          )}
          {isOpen && showElement && (
            <HStack
              bg={XelaColor.Blue3}
              borderRadius="16px"
              spacing="8px"
              height="48px"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                dispatch(updateShowHubAddLeadModal(true))
              }}
            >
              <Typography style={{ minWidth: '115px' }} variant="body-small-bold" color={XelaColor.White}>
                Create New Lead
              </Typography>
            </HStack>
          )}
        </HStack>
      </Center>
      <Divider variant="dotted" />
      <VStack spacing="12px" className="grow">
        {SIDEBAR_ITEMS.filter((item) => {
          if (item.type !== 'item') {
            return true
          }

          if (item.roles && item.roles.includes('*')) {
            return true
          }

          return item.roles && agentRole && item.roles.includes(agentRole.role_name)
        }).map((item, index) => {
          if (item.type === 'divider') {
            return <Divider key={index} variant="dotted" />
          }

          if (item.type === 'grow') {
            return <Box key={index} className="grow"></Box>
          }

          const isActiveCommission = item.route === '/hub/commissions' && url.startsWith('/hub/commission/')

          return (
            <HStack
              key={index}
              onClick={() => {
                if (item.route) {
                  if (item.route === '/hub/commissions') {
                    dispatch(toggleHubCommissionSubSidebar(!globalStore.isHubCommissionSubSidebarOpen))
                  } else router.visit(item.route)
                }
              }}
              spacing="12px"
              style={
                (item.route && url.startsWith(item.route)) || isActiveCommission
                  ? {
                      padding: '8px',
                      paddingLeft: '16px',
                      backgroundColor: XelaColor.Blue12,
                      borderRadius: '12px',
                      width: '100%',
                    }
                  : {
                      padding: '8px',
                      paddingLeft: '16px',
                    }
              }
              className="sidebar-icon-btn"
            >
              <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
                {item.icon}
              </Center>
              {isOpen && (
                <>
                  <Typography variant="body-small-bold">{item.label}</Typography>
                  {(item.route === '/tasks' || item.route === '/follow-ups') && (
                    <ChipX
                      label={item.route === '/tasks' ? meta.counts.tasks.toString() : meta.counts.followUps.toString()}
                      size="small"
                    ></ChipX>
                  )}
                </>
              )}
            </HStack>
          )
        })}
      </VStack>
      <HStack spacing="12px" justifyContent="center">
        <Avatar size="small" image={<img src={avatar || agent.avatar} alt="avatar" />} />
        {isOpen && showElement && (
          <>
            <VStack
              style={{
                minWidth: dimension > 1440 ? '150px' : '130px',
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="body-small-bold" color={XelaColor.Gray2}>
                {agent.name}
              </Typography>
              <Typography variant="caption" color={XelaColor.Gray6}>
                {agent.email}
              </Typography>
            </VStack>
            <Center width="16px" style={{ minWidth: '16px' }} className="sidebar-icon-btn">
              <IconOff
                width="16"
                height="16"
                viewBox="0 0 15 15"
                onClick={() => {
                  router.post(route('hub.logout'))
                }}
              ></IconOff>
            </Center>
          </>
        )}
      </HStack>

      <HubSubSidebar />
    </div>
  )
}

export default HubSidebar
